.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:2%;
}
.container p{
    font-size: 20px;
    font-family: Inter;
    font-style: normal;
    line-height: 120%;
    letter-spacing: 1px;
    text-align: left;
    margin-top: 1%;
    margin-bottom: 1%;

}
.container h2{
    color: #d4391a;

    font-family: Inter;
    font-size: 35px;
    font-style: normal;
    line-height: 120%;
    letter-spacing: 0.9px;
    text-align: left;
    margin-bottom: 2%;

}
.imagecon {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 15px;
    gap: 16px;
    margin-top: 26px;
    border: 1px solid #E85112;
    background: rgba(255, 255, 255, 0.65);
    box-shadow: 
      0px 0px 3.107px 0px rgba(24, 20, 31, 0.06), 
      0px 24.856px 55.925px 0px rgba(24, 20, 31, 0.15);
  }
  .imagecover{
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
  .boldLarge{
    font-weight: 600;
    font-size: 1.7vw;
 
  }
  
@media (max-width: 768px) {
    .container{
        padding: 5%;
    }
    .container p{
        font-size: 4vw;
        font-family: Inter;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: 1.2px;
        text-align: left;
        margin-top: 3%;
        margin-bottom: 1%;
    
    }
    .container h2{
        color: #d4391a;
    
        font-family: Inter;
        font-size: 7vw;
        font-style: normal;
        line-height: 120%;
        letter-spacing: 0.9px;
        text-align: left;
        margin-bottom: 2%;
    
    }
    .imagecon{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 2%;
    }
    .imagecover {
        width: 100%; /* Adjust width to fit two images in a row */
        height: auto;/* Maintain aspect ratio */
        margin-bottom: 8px; /* Add spacing between rows */
      }
      .boldLarge{
        font-weight: 600;
        font-size: 5.25vw;
     
      }
}
