.outer{
    display: flex;
    flex-direction: column;
}
.container{
    display: flex;
    flex-direction: row;
    margin-top: 6.2%;

}
.imageSection{
    display: flex;
    width: 45%;
    background-color: #333333;

}
.client{
    width:100%
}
/* .formContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
} */
.formContainer {
  padding: 0% 8%;
  width: 55%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  }
  
  .title {
    margin-bottom: 1vw;
    color: #111D15;

font-family: Poppins;
font-size: 2.5vw;
font-style: normal;
font-weight: 600;
line-height: 120%;
  }
  
  .subtitle {
    margin-bottom: 1.5vw;
    text-align: left;
    color: #666;
font-family: Poppins;
font-size: 1.2vw;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 36px */
letter-spacing: -0.24px;
  }
  input::placeholder,textarea::placeholder {
    color: #666;
    font-family: Poppins;

  }
  
  .form {

    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    margin-bottom: 2%;
    border-radius: 5px;
    font-size: 1vw;
    box-sizing: border-box;
    border-radius: 7.179px;
border: 1.795px solid #cccccc;

background: #FFF;
font-family: Poppins;

box-shadow: 0px 5.326px 6.658px 0px rgba(0, 0, 0, 0.07);

  }
  
  .textarea {
    width: 100%;
    padding: 10px;
    height: 12vw;
    margin-bottom: 5%;
    border-radius: 5px;
    font-size: 1vw;
    box-sizing: border-box;
    border: 1.795px solid #cccccc;
    font-family: Poppins;

background: #FFF;

box-shadow: 0px 5.326px 6.658px 0px rgba(0, 0, 0, 0.07);
  }
 
  .button {
    background-color: #E85112;
    color: #ffffff;
    padding: 12px 0;
    border: none;
    border-radius: 5px;
    font-size: 1.2vw;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: Poppins;

  }
  
  .button:hover {
    background-color: #fc2e04;
  }
  .container2{
    border-radius: 12px;
    border: 1px solid rgba(102, 102, 102, 0.64);
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  }
  .container2 {
    margin: 2% 3%;
    padding: 50px;
    background-color: #f9f9f9; /* Adjust background color as needed */
    border-radius: 8px;
  }
  .contactItem {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 2%;
  }
  
  .ficon {
    color: #E85112; /* Adjust the color of icons */
    font-size: 1.2rem;
  }
  
  .location{
    margin: 0;
    color: var(--Black, #000);
font-family: Poppins;
font-size: 1.35vw;
font-style: normal;
font-weight: 500;
line-height: normal;
  }
  .head1{
    color: #000;
    font-family: Poppins;
    font-size: 2.5vw;
    font-style: normal;
    font-weight: 700;
    line-height: 200%;
    letter-spacing: 0.9px;
  }
  .head2{
    color: var(--Black, #000);
font-family: Poppins;
font-size: 1.5vw;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 2%;
text-align: left;

  }
.head3{
    color: #666;
font-family: Poppins;
font-size: 1.35vw;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 2%;
text-align: left;


}
.socialIcons {
  display: flex;
  gap: 1vw; /* Adds space between the icons */
}

.icon {
  width: 3vw; /* Adjust size as needed */
  height: 3vw;
  object-fit: contain; /* Ensures the icon retains its aspect ratio */
  cursor: pointer; /* Changes the cursor to a pointer on hover */
  transition: transform 0.3s ease; /* Adds a hover animation */
}

.icon:hover {
  transform: scale(1.1); /* Slightly enlarges the icon on hover */
}
  @media screen and (max-width: 1440px) {

  .navLinks a {
    color: #fff;
    font-family: Poppins;
    font-size: 18px;
  }
}
 
  @media screen and (max-width: 768px) {

    .container{
      flex-direction: column;
      height: auto;
      justify-content: center;
      align-items: center;

    }
    .imageSection{
      display: flex;
      width: 100%;
      background-color: #333333;
  
  }
  
  .formContainer {
      border-radius: 10px;
      padding: 5%;
      width: 90%;
      box-shadow: none;
      border:1px solid black;
      text-align: center;
      margin-top: 5%;
border: 1.802px solid #eda384;
background: #FFFBFB;

box-shadow: 0px 23.782px 47.565px -5.946px rgba(40, 37, 35, 0.10), 0px 2.973px 5.946px 0px rgba(40, 37, 35, 0.04);
    }
    .title {
      margin-bottom: 2.5vw;
      color: #111D15;
  
  font-family: Poppins;
  font-size: 6.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
    }
    
    .subtitle {
      margin-bottom: 2.5vw;
      text-align: left;
      color: #666;
  font-size: 3.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  letter-spacing: -0.24px;
    }
    input::placeholder,textarea::placeholder {
      color: #666;
    }
    
    .form {
  
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    
    .input {
      width: 100%;
      padding: 10px;
      margin-bottom: 3.5%;
      border-radius: 5px;
      font-size: 4.5vw;
      box-sizing: border-box;
      border-radius: 7.179px;
  border: 1.795px solid #cccccc;
  
  background: #FFF;
  
  box-shadow: 0px 5.326px 6.658px 0px rgba(0, 0, 0, 0.07);
  
    }
    
    .textarea {
      width: 100%;
      padding: 10px;
      height: 42vw;
      margin-bottom: 8%;
      border-radius: 5px;
      font-size: 4.5vw;
      box-sizing: border-box;
      border: 1.795px solid #cccccc;
  
  background: #FFF;
  
  box-shadow: 0px 5.326px 6.658px 0px rgba(0, 0, 0, 0.07);
    }
    .button {
      font-size: 4.5vw;
    }
    .container2 {
      margin: 7%;
     padding:6%;
     border: 1.802px solid #eda384;

    }
    .head1{
      color: var(--Black, #000);
  font-size: 6.5vw;
  font-style: normal;
  font-weight: 550;
  line-height: normal;
  text-align: left;
  margin-bottom:7% ;

    }
    .head2{
      color: var(--Black, #000);
  font-size: 5vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 5%;
    }
  .head3{
      color: #666;
  font-size: 3.7vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 3%;
  
  }
  .location{
    margin: 0;
    color: var(--Black, #000);
font-size: 3.7vw;
font-style: normal;
font-weight: 550;
line-height: normal;
text-align: left;
  }
  .contactItem {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 7%;
  }
  .icon {
    width: 10vw;
    height: 10vw;
  }
  .ficon {
    color: #E85112; /* Adjust the color of icons */
    font-size: 5.5vw;
  }
  
  }