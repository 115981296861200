.container {
  font-family: "PoppinsRegular", sans-serif;
  display: flex;
  width: 100%;
  background-color: #f5f5f5;
  padding: 1.5%;
  margin-top: 5%;
}

.imageSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.image {
  height: auto;
  object-fit: cover;
  width: 100%;
}

.formSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 2rem;
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);
  font-family: "PoppinsRegular", sans-serif;
}

.logo {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 5vw;
}

.logoImage {
  height: 100%;
}

.namem {
  font-size: 1.8vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e85112;
  font-family: "PoppinsRegular", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.291px;
}

.formHeading {
  margin-bottom: 2vw;
  color: #6b6b6b;

  text-align: center;
  font-family: "PoppinsRegular", sans-serif;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.291px;
}
.toast{
  top: calc(50% + 25px); /* Adjust for header height (25px offset) */
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  margin: 0;
}
.form {
  width: 80%;
}

.label {
  font-size: 1rem;
  color: #666;
  margin-bottom: 0.5rem;
  display: block;
  color: var(--Black, #000);
  /* Text Medium */
    font-family: "PoppinsRegular", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin-top: 1vw;
}

.input {
  width: 100%;
  padding: 0.8rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 0.9rem;
}

.rememberMe {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.rememberMe label {
  margin-left: 0.5rem;
  font-size: 0.9rem;
  color: #666;
}

.loginButton {
  width: 100%;
  padding: 0.8rem;
  background-color: #f25b3c;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.loginButton:hover {
  background-color: #d94a2c;
}
@media (max-width: 916px) {
  .formHeading {
    margin-top: -15%;
  }
  .label {
    font-size: 1rem;
    color: #666;
    margin-bottom: 0.5rem;
    display: block;
    color: var(--Black, #000);
    /* Text Medium */
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin-top: 0vw;
  }
}
@media (max-width: 768px) {
  .formHeading {
    margin-top: 0%;
  }
  .label {
    font-size: 1rem;
    color: #666;
    margin-bottom: 0.5rem;
    display: block;
    color: var(--Black, #000);
    /* Text Medium */
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin-top: 1vw;
  }
  .container {
    flex-direction: column;
    height: auto;
  }
  .imageSection {
    width: 100%;
  }
  .namem {
    font-size: 5vw;
  }
  .formHeading {
    font-size: 4vw;
    margin-bottom: 3vw;
  }
}
