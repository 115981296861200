.outer {
  margin: 4%;
  margin-top: 8%;
}

.namecontainer {
  display: flex;
  align-items: center;
  border-radius: 25px;
  overflow: hidden; /* Ensures no content spills out */
  width: 53%; /* Adjust as needed */
}

.rectangle {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding:  1.75vw 1.5vw;
  border-radius: 1.3vw 0 0 1.3vw;
  background: #e85112;
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 1.6vw; /* Responsive text size */
  font-weight: 700;
  letter-spacing: 0.9px;
  flex: 1; /* Takes up the remaining space */
  /* padding-left: 5%; */
}

.imagelogo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%; /* Adjust width for better balance */
  background: #fff; /* Optional for a contrasting background */
  padding: 0;
}

.client {
  width: 8vw;
  height: 6.2vw;
  object-fit: contain; /* Maintains image aspect ratio */
  margin-left: -80%;
}
.container {
  width: 100%;
  display: flex;
  margin-top: 0.7%;
}
.descContainer {
  width: 65%;
}
.imageSection {
  width: 35%;
  border-radius: 10.371px;
  border: 0.864px solid #e85112;

  background: #fffbfb;

  box-shadow: 0px 20.554px 41.108px -5.139px rgba(40, 37, 35, 0.1),
    0px 2.569px 5.139px 0px rgba(40, 37, 35, 0.04);
  display: flex;
  padding: 1%;
  align-items: center;
  height: fit-content;
}
.meghna {
  width: 100%;
  height: auto;
  object-fit: contain; /* Maintains image aspect ratio */
}
.head {
  color: #000;

  text-align: center;
  font-family: Poppins;
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 700;
  line-height: 206.66%; /* 206.667% */
  letter-spacing: 0.9px;
  margin-bottom: 2%;
}
.description {
  color: #000;

  text-align: justify;
  font-family: Poppins;
  font-size: 0.95vw;
  font-style: normal;
  font-weight: 400;
  line-height: 350%; /* 344.444% */
  letter-spacing: 0.54px;
  margin-left: 7%;
}
.socialIcons {
  display: flex;
  gap: 1vw; /* Adds space between the icons */
  margin-left: 7%;
  margin-top: 4%;
}

.icon {
  width: 3vw; /* Adjust size as needed */
  height: 3vw;
  object-fit: contain; /* Ensures the icon retains its aspect ratio */
  cursor: pointer; /* Changes the cursor to a pointer on hover */
  transition: transform 0.3s ease; /* Adds a hover animation */
}

.icon:hover {
  transform: scale(1.1); /* Slightly enlarges the icon on hover */
}
.description2 {
  color: #000;

  text-align: justify;
  font-family: Poppins;
  font-size: 0.95vw;
  font-style: normal;
  font-weight: 500;
  line-height:  322.222% ;
  letter-spacing: 0.54px;
}
.head2 {
  color: #6b6b6b;

  text-align: center;
  font-family: Poppins;
  font-size: 1.58vw;
  font-style: normal;
  font-weight: 600;
  line-height:  206.667% ;
  letter-spacing: 0.9px;
  margin-top: 1%;
  margin-bottom: 1%;
}
.superhead {
  color: #e85112;
  text-align: center;
  font-family: Inter;
  font-size: 1.794vw;
  font-style: italic;
  font-weight: 700;
  line-height: 116.429%;
  letter-spacing: 0.305px;
}
.container2 {
  border-radius: 12px;
  border: 1px solid #e85112;

  background: #fff;

  box-shadow: 0px 0px 3.107px 0px rgba(24, 20, 31, 0.06),
    0px 24.856px 55.925px 0px rgba(24, 20, 31, 0.15);
  display: flex;
  flex-direction: column;
  width: 85%;
  padding: 2% 5%;
}
.middlesec {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 3%;
}
.head3 {
  color: #000;
  font-family: Poppins;
  font-size: 2vw;
  font-style: normal;
  font-weight: 700;
  line-height: 200%; 
  letter-spacing: 0.9px;
  text-align: left;
}
.head4 {
  color: #000;

  font-family: Poppins;
  font-size: 1.16vw;
  font-style: normal;
  font-weight: 600;
  line-height:  204.545%;
  letter-spacing: 0.66px;
}
.description3 {
  font-family: "Poppins", sans-serif;
  font-size: 0.95vw; /* Adjust for responsiveness */
  line-height: 1.6; /* Better readability */
  color: #000;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.description3 p {
  margin-bottom: 1.2vw; /* Space between paragraphs */
  text-align: left;
  font-size: 0.95vw;
    font-weight: 500;
}

.description3 ul {
  list-style-type: disc; /* Standard bullet points */
  padding-left: 1vw; /* Indent for bullets */
  margin-bottom: 1vw;
  font-size: 0.95vw;
    font-weight: 500; /* Space after the list */
}

.description3 li {
  margin-bottom: 0.5vw; /* Space between list items */
  text-align: left;
}
.lowermiddle,.lower {
  border-radius: 12px;
  border: 1px solid #e85112;

  background: #fffbfb;

  box-shadow: 0px 23.782px 47.565px -5.946px rgba(40, 37, 35, 0.1),
    0px 2.973px 5.946px 0px rgba(40, 37, 35, 0.04);
  padding: 1%;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.teamimage {
  width: 100%;
}
.team {
  width: 100%;
  height: auto;
}
.lower{
  flex-direction: row;
  justify-content: space-around;
}
.content ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 0.55rem;
  text-align: left;
line-height: 210%;
letter-spacing: 0.44px;
/* max-width: 90%; */
font-size: 1.1vw;
}
.content p{
  text-align: left;
  font-size: 1.1vw;
  font-weight: 450;
  margin-bottom: 1%;
  line-height: 200%; 

}

.content ul li {
  margin-bottom: 0.25vw;
  height:10%;

}
.desclower{
  padding-right:1.5%;
  /* height:500px; */

}
/* .imagelower{
  width: 40%;
  border: 1px solid black;
  height: 500px;
  display: flex;
  justify-content: flex-end;

}
.meghna2{
  width: auto;
  height: 100%;
  object-fit: contain;
} */
.imagelower{
  width: 40%;
  height: fit-content;
  display: flex;
  justify-content: flex-end;

}
.meghna2{
  width: 100%;
  height: auto;
  object-fit: contain;
}
@media (max-width: 916px) {
  .outer{
    margin-top: 13%;
  }
  .namecontainer{
    width:80%;
  
  }
  .rectangle {
    font-size: 2.5vw;
    padding-top: 3%;
    padding-bottom: 3%;

    border-radius: 1.75vw 0 0 1.75vw;
    /* padding-left: 1vw; */
  
  }
  .imagelogo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%; /* Adjust width for better balance */
    padding: 0;
  }
  
  .client {
    width: 20vw;
    height: 12vw;
    object-fit: contain; /* Maintains image aspect ratio */
    margin-left: -55%;
  }
}
@media (max-width: 768px) {
  .outer{
    margin-top: 12%;
  }
  .container{
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #E85112;
    
    background: #FFFBFB;
    
    box-shadow: 0px 23.782px 47.565px -5.946px rgba(40, 37, 35, 0.10), 0px 2.973px 5.946px 0px rgba(40, 37, 35, 0.04);    padding:6%;
  }
  .imageSection{
    width: 100%;
    border: none;
  }
  .descContainer{
    width: 100%;
    margin-top: 4%;
}
  .head{
    color: #000;

text-align: center;
font-family: Poppins;
font-size: 6vw;
font-style: normal;
font-weight: 700;
line-height: 141.818% ;
letter-spacing: 0.66px;
  }
  .description{
    color: #000;

text-align: justify;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height:  251.429%;
letter-spacing: 0.42px;
margin-left: 0%;
padding: 2%;
  }
  .icon {
    width: 10vw;
    height: 10vw;
  }
  .socialIcons{
    display: flex;
    gap: 1vw;
    margin-left: 0%;
    margin-top: 2%;
    /* align-items: center; */
    justify-content: center;
}
.container2{
  width: 100%;
    padding: 5% 2%;
}
.superhead{
  font-size: 28px;

}
.head2{
  font-size: 22px;
  line-height:  125.182%;
  margin-top: 3%;

}
.description2{
  color: #000;

text-align: justify;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 280.143% ;
letter-spacing: 0.42px;
padding: 4%;
}
.lowermiddle{
  justify-content: center;
  align-items: center;
  padding-top: 3%;
  padding-bottom: 5%;
}
.head3{
  font-size: 6vw;
  text-align: center;

}
.head4{
  font-size: 20px;
  font-weight: 500;
line-height: 195%;
letter-spacing: 0.6px;
text-align: left;
margin-bottom: 5%;
}
.description3{
  font-size: 14px;

}
.description3 p {
  margin-bottom: 3.5vw; /* Space between paragraphs */
  text-align: left;
  font-size: 14px;
    font-weight: 500;
}

.description3 ul {
  list-style-type: disc; /* Standard bullet points */
  padding-left: 1vw; /* Indent for bullets */
  margin-bottom: 3.5vw;
  font-size: 14px;
    font-weight: 500; /* Space after the list */
}

.description3 li {
  margin-bottom: 1.5vw; /* Space between list items */
  text-align: left;
}
.lower{
  flex-direction: column;
  justify-content: center;
  padding-top: 3%;
  padding-bottom: 5%;
}
.imagelower{
width: 100%;
}
.content ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 0.55rem;
  text-align: left;
line-height: 210%;
letter-spacing: 0.44px;
max-width: 100%;
font-size: 3.5vw;
}
.content p{
  text-align: left;
  font-size: 4vw;
  font-weight: 450;
  margin-bottom: 1%;
  line-height: 180%; 

}

.content ul li {
  margin-bottom: 0rem;
  height:10%;

}
.rectangle {
  font-size: 3.23vw;
  padding-top: 4%;
  padding-bottom: 4%;

  border-radius: 1.5vw 0 0 1.5vw;
  padding-left: 3vw;

}
.namecontainer{
  width:100%;

}
.imagelogo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%; /* Adjust width for better balance */
  padding: 0;
}

.client {
  width: 30vw;
  height: 25vw;
  object-fit: contain; /* Maintains image aspect ratio */
  margin-left: -55%;
}
}
