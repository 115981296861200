

.container {
    position: relative;
    text-align: center;
 /* Add your image path */
    background-size: cover; 
    aspect-ratio: 2;
    background-repeat: no-repeat;
    margin-top: 6%;
    width: 100%;
    
  }
  .dots {
    position: absolute;
    bottom: 5%;
    right: 0%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
  }
  
  .dot {
    width: 1.35vw;
    height:  1.35vw;
    border-radius: 50%;
    background-color: white;
    opacity: 0.5;
    cursor: pointer;
    border:2px solid #e85112

  }
  
  .dot.active {
    opacity: 1;
    background-color: #e85112;
  }
  /* Social Icons */
  .socialmedia {
    position: absolute;
    top: 12%;
    left: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .socialIcons {
    display: flex;
    gap: 1vw; /* Adds space between the icons */
    margin-left: 7%;
    margin-top: 4%;
    flex-direction: column;
    top: 50%;
        transform: translateY(30%);


  }
  
  .icon {
    width: 3vw; /* Adjust size as needed */
    height: 3vw;
    object-fit: contain; /* Ensures the icon retains its aspect ratio */
    cursor: pointer; /* Changes the cursor to a pointer on hover */
    transition: transform 0.3s ease;
    border: 2.5px solid white;
    border-radius: 50%; 
    padding: 0%;/* Adds a hover animation */
  }
  
  .icon:hover {
    transform: scale(1.1); /* Slightly enlarges the icon on hover */
  }
  

   
  .overlayText {
    position: absolute;
    /* top: 20%; */
    right: 5%;
    /* transform: translateX(70%); */
    padding: 2.84vw 2.77vw;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.20);
    height: fit-content;
    backdrop-filter: blur(50px);
    width: 30%;
    color: #FFF;

text-align: center;
font-family: Poppins;
font-size: 2vw;
font-style: normal;
font-weight: 600;
line-height:  155%;
letter-spacing: 1.2px;
top: 60%;
        transform: translateY(-30%);

  }
  .midcontainer{
    margin:2% 4%;
    
  }
  .namecontainer {
    display: flex;
    align-items: center;
    border-radius: 25px;
    overflow: hidden; /* Ensures no content spills out */
    width: 53%; /* Adjust as needed */
    /* border: 1px solid black; */
  }
  
  .rectangle {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding:  1.75vw 1.5vw;
    border-radius: 1.3vw 0 0 1.3vw;
    background: #e85112;
    color: #fff;
    font-family: Poppins, sans-serif;
    font-size: 1.6vw; /* Responsive text size */
    font-weight: 700;
    letter-spacing: 0.9px;
    flex: 1; /* Takes up the remaining space */
    /* padding-left: 5%; */
  }
  
  .imagelogo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%; 
    padding: 0;
  }
  
  .client {
    width: 8vw;
    height: 6.2vw;
    object-fit: contain; /* Maintains image aspect ratio */
    margin-left: -80%;
  }
  .lower{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-radius: 12px;
border: 1px solid #E85112;

background: #FFFBFB;

box-shadow: 0px 23.782px 47.565px -5.946px rgba(40, 37, 35, 0.10), 0px 2.973px 5.946px 0px rgba(40, 37, 35, 0.04);
padding-top: 2%;
padding-right: 2%;
padding-bottom: 2%;
margin-top: 1%;

}
.low{
  display: flex;
    flex-direction: row;
    padding-top: 2%;
padding-bottom: 2%;
}
  .content{
    padding-right: 3%;
    padding-left: 3%;

  }
  .content ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 0.55rem;
    text-align: left;
  line-height: 210%;
  letter-spacing: 0.44px;
  /* max-width: 90%; */
  font-size: 1.07vw;
  max-width: 98%;
  }
  .content p{
    text-align: left;
    font-size: 1.1vw;
    font-weight: 450;
    margin-bottom: 1%;
    line-height: 200%; 
  
  }
  
  .content ul li {
    margin-bottom: 1vw;
    height:10%;
  
  }
  
  .imagelower{
    width:35%;
    height: fit-content;
  display: flex;
  justify-content: flex-end;
  }
  .meghna2{
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  .carouselContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2% 2%;
    width: 96.3%;
    padding-right: 2%;
padding-left: 2%;
}

.navButtons {
    display: flex;
    justify-content: space-around;
    width: 10%;
    height: fit-content;
}

.navButton {
    background-color: #f05454;
    color: white;
    border: none;
    padding: 0.5vw 1vw;
    border-radius: 0.51vw;
    font-size: 1.2vw;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.navButton:hover {
    background-color: #d43d3d;
}

.navButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.cardsGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    gap: 3vw;
    width: 100%;
}

.card {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    background-color: #fff;
    border: 1px solid #E85112;
    border-radius: 8px;
    box-shadow:0px 4px 6px 0px rgba(0, 0, 0, 0.12);;
    padding: 1.5vw;
    text-align: center;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.cardImage {
    width: 100%;
    height: auto;
    /* max-height: 150px; */
    border-radius: 4px;
    object-fit: cover;
    margin-bottom: 1vw;
}

.card h3 {
    font-size: 1.4vw;
    margin-bottom: 0.5vw;
    color: #E85112;

font-family: Poppins;
font-style: normal;
font-weight: 700;
line-height: normal;
text-align: left;
}

.card h4 {
    font-size: 1.2vw;
    color: #333;
    margin-bottom: 1vw;
    color: #000;

font-family: Poppins;
font-style: normal;
font-weight: 700;
line-height: normal;
text-align: left;
letter-spacing: 0.44px;
    
}

.card p {
    font-size: 1vw;
    color: #666;
    line-height: 1.4;
    color: #000;

text-align: justify;
font-family: Poppins;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.36px;
}
.carouselheading{
  display: flex;
  width:100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.7vw;
}
.modi_con{
  width:50%;
  /* height: fit-content; */
display: flex;
justify-content: flex-end;
border-radius: 12px;
border: 1px solid #E85112;


background: var(--White-white-500, #FFF);
box-shadow: 0px 23.782px 47.565px -5.946px rgba(40, 37, 35, 0.10), 0px 2.973px 5.946px 0px rgba(40, 37, 35, 0.04);
padding: 2vw 1.5vw;
align-items: center;
}
.with_modi{
  width: 100%;
  height: auto;
  object-fit: contain;
}
.con{
  width:50%;
  display: flex;
  justify-content: center;
  margin-left: 2%;
  padding-right: 0;

  /* align-items: center; */
}
.gridContainer {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
  grid-template-columns: repeat(2, 1fr); 
  grid-template-rows: repeat(3, 1fr);     /* Three rows */

  gap: 1vw;
  padding: 1vw;
  padding-right: 0%;
  /* margin-left: 5%; */
}

.gridCard {
  background: white;
  border: 2px solid #ffa500; /* Orange border */
  border-radius: 10px;
  padding: 1vw;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.gridCard:hover {
  transform: translateY(-5px);
}

.gridTitle {
  font-size: 1.05vw;
  margin-bottom: 0.5vw;
  text-align: center;
  color: #000;

text-align: left;
font-family: Poppins;
font-style: normal;
font-weight: 600;
letter-spacing: 0.54px;
}

.gridDescription {
  font-size: 0.88vw;
  line-height: 1.5;
  color: #000;

  text-align: left;
  font-family: Poppins;
font-style: normal;
font-weight: 500;
letter-spacing: 0.54px;
line-height:  194.444% 

}
.content ul li span{
  font-weight: 600;
}
.mobileCarousel {
display: none;}
.mcarouselContainer{
  display: none;
}
@media (max-width: 916px) {
  .container{
    margin-top: 16%;

  }
  .dots {
    position: absolute;
    bottom: 5%;
    right: 0%;
    transform: translateX(-10%);
    display: flex;
    gap: 10px;
  }
  .overlayText{
    font-size: 3vw;
        max-width: 60%;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: 0.302px;
        right: 1%;
        top: 60%;
        transform: translateY(-50%);

  }
  .icon{
    height:5vw;
    width:5vw;
    border: 1.5px solid white;

  }
  .namecontainer{
    width:80%;
  
  }
  .rectangle {
    font-size: 2.5vw;
    padding-top: 3%;
    padding-bottom: 3%;

    border-radius: 1.75vw 0 0 1.75vw;
    /* padding-left: 1vw; */
  
  }
  .imagelogo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%; /* Adjust width for better balance */
    padding: 0;
  }
  
  .client {
    width: 20vw;
    height: 12vw;
    object-fit: contain; /* Maintains image aspect ratio */
    margin-left: -55%;
  }
}
@media (max-width: 768px) {
  .container{
    height: 32vh;
    margin-top: 12.5%;

  }
  .overlayText{
    font-size: 3vw;
        width: 60%;
        font-style: normal;
        padding: 2%;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: 0.302px;
        left:38%;
        top: 70%;
        transform: translateY(-20%);

  }
  .dot {
    width: 3.35vw;
    height:  3.35vw;
    border-radius: 50%;
    background-color: white;
    opacity: 0.5;
    cursor: pointer;
    border:2px solid #e85112

  }
  .icon{
    height:9vw;
    width:9vw;
    border: 1.5px solid white;

  }
  .socialmedia {
    position: absolute;
        top: 35%;
        left: 2%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        transform: translateY(-60%);
  }
  .socialIcons {
    display: flex;
    gap: 1vw; /* Adds space between the icons */
    margin-left: 7%;
    margin-top: 4%;
    flex-direction: column;

  }
  .rectangle {
    font-size: 3.25vw;
    padding-top: 4%;
    padding-bottom: 4%;

    border-radius: 1.5vw 0 0 1.5vw;
    padding-left: 3vw;
  
  }
  .namecontainer{
    width:100%;
  
  }
  .imagelogo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%; /* Adjust width for better balance */
    padding: 0;
  }
  
  .client {
    width: 30vw;
    height: 25vw;
    object-fit: contain; /* Maintains image aspect ratio */
    margin-left: -55%;
  }
  .lower{
    flex-direction: column-reverse;
    padding: 5%;
    }
  .imagelower{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
}
.content ul{
  color: #000;

text-align: justify;
font-family: Poppins;
font-size: 14px;
font-style: normal;
/* font-weight: 600; */
line-height:  245.143%;
letter-spacing: 0.28px;
}
.content{
  padding-right: 1%;
  padding-left: 1%;

}
.content ul li {
  margin-bottom: 2.5vw;
}
.content ul li span{
  font-weight: 600;
}
.navButtons{
  display: none;
}
.cardsGrid {
  display: none;
  
}
.mobileCarousel {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.mobileCard {
  width: 100%;
  opacity: 0; /* Hide by default */
  transform: scale(0.8); /* Shrink hidden cards */
  transition: opacity 0.5s, transform 0.5s;
  border-radius: 2.895px;
  border: 0.724px solid #E85112;
  background: #FFF;
  box-shadow: 0px 2.895px 2.895px 0px rgba(0, 0, 0, 0.12);
  padding: 4%;
}

.activeCard {
  opacity: 1;
  transform: scale(1); /* Highlight active card */
}

.hiddenCard {
  display: none; /* Completely hide hidden cards */
}

.mobileImage {
  width: 100%;
  border-radius: 10px;
}

.mobileCardContent {
  text-align: center;
}

.mobileYear {
  font-size: 6.5vw;
  color: #E85112;

font-family: Poppins;
font-style: normal;
font-weight: 800;
line-height: normal;
margin-top: 3%;
margin-bottom: 2%;
text-align: left;


}

.mobileTitle {
  font-size: 5.6vw;
  color: #000;

font-family: Poppins;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.318px;
text-align: left;
}

.mobileDescription,.mcardDescription {
  color: #000;
        text-align: justify;
        font-family: Poppins;
        font-size: 4vw;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: 0.261px;
        margin-top: 3%;
}

.mobileNavigation {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-top: 3%;
}

.mobileNavButton {
  height: 12vw;

  cursor: pointer;
  
}

.low{
  flex-direction: column;
}
.modi_con{
  width: 100%;
  margin-bottom: 2%;

}
.con{
  display: none;
}
.mcarouselContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  /* padding: 20px; */
}

.mcard {
  border: 2px solid #ff6600;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.mcardTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}



.mcarouselControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin-top: 10px;
}

.mcarouselButton {
  border: none;
  background-color: transparent;
  font-size: 20px;
  cursor: pointer;
  color: #ff6600;
}

.mcarouselButton:hover {
  color: #ff4500;
}

}