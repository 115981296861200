
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'EBGaramond';
  src: url('../public/fonts/EBGaramond.ttf') format('truetype');
}

@font-face {
  font-family: 'EBGaramondItalic';
  src: url('../public/fonts/EBGaramondItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsBold';
  src: url('../public/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsSemiBold';
  src: url('../public/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsRegular';
  src: url('../public/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsMedium';
  src: url('../public/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}