.container {
    position: relative;
    text-align: center;
    background-image: url('../images/event.png'); /* Add your image path */
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 6%;
    height:80vh;
    
  }
  .overlayText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 165, 0, 0.8);
    padding: 2vw 2vw;
   
    background: rgba(0, 0, 0, 0.4);
  height: fit-content;
    backdrop-filter: blur(50px);
    max-width: 50%;
    color: #FFF;

text-align: center;
font-family: Poppins;
font-size: 2.5vw;
font-style: normal;
font-weight: 600;
line-height:  155%;
letter-spacing: 1.2px;
  }
  .eventhead{
    color: #FFF;

text-align: center;
font-family: Poppins;
font-size: 1.55vw;
font-style: normal;
font-weight: 500;
line-height: 172.222% ;
letter-spacing: 1.08px;
text-transform: capitalize;
  }
  .eventhead2{
    color: #FFF;
font-family: Poppins;
font-size: 2.25vw;
font-style: normal;
font-weight: 700;
line-height: 62px;
letter-spacing: 1.32px;
text-transform: capitalize;
  }
  .nextcontainer{
    display:flex;
    margin-top: 2%;
    margin-bottom: 2%;

  }
  .sec1{
    width:30%;
    padding-left: 1.5%;

  }
  .con2{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    flex-wrap: wrap;
            gap: 16px;
  }
  .descimage{
    width: 30%;
  }
  .datecontainer {
    font-family: 'Arial, sans-serif';
    max-width: 400px;
    margin: 20px auto;
    text-align: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .heading {
    margin-bottom: 20px;
  }
  
  .inputGroup {
    margin-bottom: 10px;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .button {
    padding: 10px 20px;
    background-color: #d9534f;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  .searchContainer {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 3px;
    width:90%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .inputField {
    flex: 1;
    border: none;
    outline: none;
    font-size: 1vw;
    color: #7a7a7a;
    /* padding: 8px; */
    padding-left: 3%;
    border-radius: 8px 0 0 8px;
  }
  
  .inputField::placeholder {
    color: #bdbdbd;
  }
  
  .searchButton {
    background-color: #d64c2e;
    color: #ffffff;
    border: none;
    /* padding: 8px 16px; */
    font-size: 16px;
    font-weight: bold;
    border-radius: 0 8px 8px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 28%;
  }
  
  .searchButton:hover {
    background-color: #bf3e20;
  }
.outersearch{
  display: flex;
  align-items: center;
  justify-content:center;
  margin-top: 3%;
}  
.mobilesearchcon{
  display: none;
}
@media (max-width: 916px) {
  .container{
    height:40vh;
  }
  .sec1{
    width:25%;
  }
  .con2{
    width:75%
  }
  .descimage{
    width: 50%;
  }
  .datecontainer {
    font-family: 'Arial, sans-serif';
    margin: 20px auto;
    text-align: center;
    
  }
}
@media (max-width: 768px) {
  .searchContainer{
    display: none;
  }
  .mobilesearchcon{
    display: flex;
    flex-direction: column;
    width:100%;
    justify-content: center;
    align-items: center;
  }
  .nextcontainer{
   flex-direction: column;

  }
  .container{
    height:45vh;
  }
  .eventhead{
    font-size: 4.5vw;
    margin-bottom: 2%;
    line-height: 156.25%;
letter-spacing: 0.48px;
  }
  .overlayText{
    max-width: 100%;
        width: 85%;
        top: 40%;
        padding: 3% 5%;
        border-radius: 10px;
  }
  .eventhead2{
    font-size: 5.2vw;
    line-height: 120%;
letter-spacing: 0.6px;
margin-bottom: 6%;

  }
  
  .inputField{
    font-size: 3.3vw;
    padding: 4%;
    border-radius: 5%;
    width: 100%;
    margin-bottom: 3%;

  }
  .searchButton{
font-size: 4vw;
width:50%;
padding: 5%;
border-radius: 7px;

  }
  .sec1{
width: 100%;
padding: 3%;
padding-left: 5%;
padding-right: 5%;
  }
  .con2{
    width: 100%;
  }
  .descimage{
    width: 85%;
  }
}