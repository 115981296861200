.container {
    padding: 2vw;
    font-family: Arial, sans-serif;
    background-color: #fff;
    margin-top: 6%;
    
  }
  
  .header{
    font-size: 1.3vw;
    color: #000;
    /* margin-bottom: 2vw; */
    text-align: left;
    font-style: normal;
font-weight: 600;
line-height:144.286%;
letter-spacing: 1px;
background-color: #FCF8F7;

  }
  
  .filterSection {
    display: flex;
    gap: 1vw;
    margin-bottom: 2vw;
  }
  
  .dropdown {
    padding: 0.8vw;
    border: 1.8px solid #E85112;
    border-radius: 20px;
    font-size: 1vw;
    color: #E85112;
    outline: none;
    appearance: none;
    background: transparent;
    width: 15vw;
    font-weight: 500;
  }
  
  .categoryButtons {
    display: flex;
        gap: 1.5vw;
        flex-wrap: wrap;
        margin-bottom: 2.5vw;
        /* justify-content: center; */
        background-color: #FCF8F7;
        padding-top: 2vw;
        padding-bottom: 1vw;



  }
  
  .button {
    padding: 0.6vw 1.5vw;
    border: 1.8px solid #E85112;
    border-radius: 20px;
    font-size: 1.25vw;
    background-color: transparent;
    color: #E85112;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .button:hover {
    background-color: #E85112;
    color: #fff;
  }
  .button.active {
    background-color: #E85112;
    color: white;
  }
  
  .contentGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 2vw;
  }
  
  .card {
    width: 31%;
    height: 350px; 
    position: relative; /* Required for absolute positioning */
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end; /* Align content to the bottom */
  }
  
  .cardImage {
    display: none; /* Hides the img tag since we use it as a background */
  }
  
  .cardContent {
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background for better text visibility */
    padding: 1vw;
    color: white;
    width: 100%;
    text-align: left;
  }
  
  .cardContent h3 {
    margin: 0;
    font-size: 1.2vw;
  }
  
  .cardContent p {
    font-size: 0.8vw;
    margin: 0.4vw 0;
    color: aqua;
  }
  
  .cardContent span {
    font-size: 0.8vw;
    color: lightgray;
  }
  
  
  .cardContent p a {
    color: lightgray;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .cardContent p a:hover {
    text-decoration: none; /* Optional: Remove underline on hover */
  }
  .sharesection{
    display: flex;
    /* border: 1px solid black; */
    justify-content: space-between;
  }
  
  
  .popupContent {
    text-align: center;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .sharePopup ul {
    list-style: none;
    padding: 0;
    margin: 0;

  }
  
  .sharePopup ul li {
    margin-bottom: 10px;
  }
  
  .sharePopup ul li a {
    color: #0077b6;
    text-decoration: none;
    font-weight: bold;
  }
  .copylink{
    color: #0077b6;
  }
  .closelink{
    color:black;
    font-weight: 500;
  }
  .sharePopup ul li a:hover {
    text-decoration: underline;
  }
  .sharePopup {
 
    background: #fff;
        border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10; /* Ensures it appears above other content */
    display: block; /* Make sure it is shown when visiblePopupIndex matches */
    border-radius: 8px;
    width:fit-content;
    padding: 2%;
    
  }  
  .vanish{
    display: flex;
    justify-content: flex-end;
  }
  .sicon{
    height:2vw;
  }
  .sgicon{
    width: 2.5vw;
  }
  /* Add scrolling to the ul container */
.scrollContainer {
  overflow-y: auto;
  padding-right: 10px; /* Optional: Add padding for better appearance */
  margin: 10px 0;
  border: 1px solid black;
  scrollbar-width: thin; /* Optional: for modern browsers */
  scrollbar-color: #ccc transparent; 
  
}

/* Style the "Close All" button */
.closeButton {
  background-color: #f44336; /* Red color */
  color: white;
  border: none;
  padding: 10px 15px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.closeButton:hover {
  background-color: #d32f2f; /* Darker red for hover */
}



/* Style icons for better appearance */

  @media (max-width: 768px) {
    .header{
      font-size: 3.8vw;
    }
    .button{
      font-size: 3.75vw;
      padding: 6.427px 6.903px;
      border-radius: 15px;
    }
    .categoryButtons {
    
          flex-wrap: wrap;
          margin-bottom: 5vw;
          padding-top: 5vw;
  
    }
    .card{
      width: 100%;
      height: 200px;
  }
.cardContent{
  padding: 3%;
}
  
  .cardContent p {
    font-size: 3vw;
    margin: 3vw 0;
    color: aqua;
  }
  
  .cardContent span {
    font-size: 3vw;
    color: lightgray;
  }
  .sicon{
  width: 15vw;
  height:12vw;
  }
  .sgicon{
    width: 15.5vw;
    height:10vw;
    margin-top: 5%;
  }
  .sharePopup ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex

  }
  .copylink{
    font-size: 4vw;
  }
  .closelink{
   
    font-size: 4vw;

  }
}