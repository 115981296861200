.galleryContainer {
    text-align: center;
    /* padding: 2rem; */
  }
  .header{
    font-size: 1.3vw;
    color: #000;
    /* margin-bottom: 2vw; */
    text-align: left;
    font-style: normal;
font-weight: 600;
line-height:144.286%;
letter-spacing: 1px;
margin-bottom: 2rem;

  }
 .headcon{
  background-color: #FCF8F7;
margin: 0%;
padding: 2rem;
margin-top: 6.5%;

 }
  
  .filters {
    margin-bottom: 2rem;
    display: flex;
  }
  
  .filters button {
    margin: 0 0.5rem;
    padding: 0.5rem 1.2rem;
    border-radius: 3.8vw;
    background-color: #FCF8F7;
    cursor: pointer;
    border: 2.418px solid #E85112;
    color: #E85112;
  }
  
  .filters .active {
    background-color: #ff5722;
    color: white;
  }
  
  .imageGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 equal-width columns */
    gap: 0vw; /* Adjust the gap between images */
    padding: 2rem;

  }
  

  
  .imageLink {
    text-decoration: none;
    color: inherit;
    
  }
  

  

  
 
 
  .imageCard {
    position: relative;
    width: 95%;
    margin: 10px;
    overflow: hidden; 
  }
  
  .image {
    width: 100%;
    height: 300px;
    display: block;
  }
  
  .imageInfo {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Dark background with transparency */
    color: #fff;
    padding:3%;
    text-align: center;
    display: flex;
    flex-direction: column;
 
  }
  
  .imageTitle {
    margin: 0;
    font-size: 1vw;
    text-align: left;
    color: #FFF;

font-family: Inter;
font-style: normal;
font-weight: 500;
line-height: 149.438%;
letter-spacing: 0.088px;
text-decoration: underline;

  }
  
  .imageDate {
    margin-top: 0.5vw;
    font-weight: lighter;
    text-align: left;
    color: #FFF;

font-family: Inter;
font-size: 1.2vw;
font-style: normal;
font-weight: 700;
line-height: 125%;
letter-spacing: 0.088px;

  }
  .sharesection{
    display: flex;
    justify-content: space-between;
  }
  
  
  .popupContent {
    text-align: center;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .sharePopup ul {
    list-style: none;
    padding: 0;
    margin: 0;

  }
  .share{
    height:1.5vw;

  }
  
  .sharePopup ul li {
    margin-bottom: 10px;
  }
  
  .sharePopup ul li a {
    color: #0077b6;
    text-decoration: none;
    font-weight: bold;
  }
  .copylink{
    color: #0077b6;
  }
  .closelink{
    color:black;
    font-weight: 500;
  }
  .sharePopup ul li a:hover {
    text-decoration: underline;
  }
  .sharePopup {
 
    background: #fff;
        border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10; /* Ensures it appears above other content */
    display: block; /* Make sure it is shown when visiblePopupIndex matches */
    border-radius: 8px;
    width:fit-content;
    padding: 2%;
    
  }  
  .vanish{
    display: flex;
    justify-content: flex-end;
  }
  .sicon{
    height:2vw;
  }
  .sgicon{
    width: 2.5vw;
  }
  /* Add scrolling to the ul container */
.scrollContainer {
  overflow-y: auto;
  padding-right: 10px; /* Optional: Add padding for better appearance */
  margin: 10px 0;
  border: 1px solid black;
  scrollbar-width: thin; /* Optional: for modern browsers */
  scrollbar-color: #ccc transparent; 
  
}

/* Style the "Close All" button */
.closeButton {
  background-color: #f44336; /* Red color */
  color: white;
  border: none;
  padding: 10px 15px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.closeButton:hover {
  background-color: #d32f2f; /* Darker red for hover */
}
.datePickerContainer {
  margin: 10px 0;
  text-align: center;
  display: flex;
}

.datePicker {
  padding: 8px 12px;
  border: 2px solid #e15d27;
  border-radius: 20px;
  font-size: 16px;
  outline: none;
  text-align: center;
  cursor: pointer;
  background-color: #FCF8F7;
  border: 2.418px solid #E85112;
  color: #E85112;
}
.datePicker::placeholder {
  color: #E85112;
}
@media (max-width: 916px) {
  .headcon{
    margin-top: 12%;

  }
}

@media (max-width: 768px) {
  .headcon{
    padding: 3.5% 5%;
    margin-top: 12%;
  }
  .header{
    font-size: 3.8vw;
    margin-bottom: 7%;

    
  }
  .filters button {
    /* border-radius: 15%; */
    cursor: pointer;
    font-size: 3.5vw;
    padding: 1.35% 1.55%;
    margin: 1%;
    border-radius: 4vw;

  }
  .imageGrid{
    grid-template-columns: repeat(1,1fr);
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
  .imageCard{
    width: 100%;
  }
 
  
  .image {
    width: 100%;
    height: 250px;
    display: block;
  }
  .imageTitle {
    margin: 0;
    font-size: 3.5vw;
    
line-height: 135%;
  }
  
  .imageDate {
    margin-top: 2.5vw;
    

font-size: 5vw;

line-height: 115%;

  }
  .categoryButtons {
    
    flex-wrap: wrap;
    margin-bottom: 5vw;
    padding-top: 5vw;

}
.card{
width: 100%;
height: 200px;
}
.cardContent{
padding: 3%;
}

.cardContent p {
font-size: 3vw;
margin: 3vw 0;
color: aqua;
}

.cardContent span {
font-size: 3vw;
color: lightgray;
}
.sicon{
width: 15vw;
height:12vw;
}
.sgicon{
width: 15.5vw;
height:10vw;
margin-top: 5%;
}
.sharePopup ul {
list-style: none;
padding: 0;
margin: 0;
display: flex

}
.copylink{
font-size: 4vw;
}
.closelink{

font-size: 4vw;

}
.share{
  height:7vw;
}
.datePicker {
  padding: 4px;

  font-size: 4vw;
} 
.filters{
  margin-bottom: 1rem;
}


}